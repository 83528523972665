.App {
  margin: 0;
  padding: 0;
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Microsoft YaHei'
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

a,
ul,
li {
  list-style-type: none;
  text-decoration: none;
}
.zrn-loading{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.zrn-loading .box{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 90%;
  height: 200px;
  text-align: center;
  line-height: 200px;
}